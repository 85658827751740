import React from 'react'

import Format from './Format.js'
import VideoPlayer from '../helpers/VideoPlayer.js'
import Button from '../Button'

import EditableField from '../helpers/EditableField.js'
import EditableArea from '../helpers/EditableArea.js'
import FormatStepEditor from '../../../admin/components/formation/player/FormatStepEditor.js'
import Dropdown from '../helpers/Dropdown.js'
import playButton from '../../../img/playButton.svg'
import replayButton from '../../../img/replayButton.svg'

import LanguageContext from "../../../contexts/LanguageContext"

class FormatVideo extends Format {
  constructor(props) {
    super(props)

    this.state = {
      isStarted: props.format.isDone ? true : false,
      isEnded: props.format.isDone ? true : false,
      isAlreadyPlayed : props.format.isDone ? true : false,
      playing: false,
      isMobile: window.innerWidth <= 600,
      //resumeButton : JSON.parse(JSON.stringify(this.props.format.buttonStart)),
    }
  }

  updateIsMobile() {
    this.setState(() => ({ isMobile: window.innerWidth <= 600 }))
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateIsMobile.bind(this))

    /*this.setState((prevstate) => ({
      resumeButton : {...prevstate.resumeButton, text: this.context.values.formatVideo.resume[this.context.language]}
    }));*/

    //console.log(this.props.format)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile.bind(this))
  }

  changeVideoState = (value) => {
    this.setState((prevstate) => ({ playing: value }))
  }


  render() {
    const buttonReplay = (
      <Button
        key="video_btn_replay"
        isEditor={this.props.isEditor}
        isLanding={this.props.isLanding}
        btnName={'image'}
        btn={this.props?.format?.image || { type: 'secondary', text: 'Revoir la vidéo' }}
        callback={() => {
          this.setState((prevstate) => ({ playing: true }))
        }}
      />
    )
    const buttonNext = (
      <Button
        key="video_btn_ended"
        isEditor={this.props.isEditor}
        isLanding={this.props.isLanding}
        btnName={'buttonNext'}
        btn={this.props.format.buttonNext}
        callback={() => {
          this.manageResponse()
        }}
      />
    )
    const buttonStart = (
      <Button
        key="video_btn_start"
        isEditor={this.props.isEditor}
        isLanding={this.props.isLanding}
        btnName={'buttonStart'}
        btn={this.props.format.buttonStart}
        callback={() => {
          this.setState((prevstate) => ({ playing: true, isStarted: true }))
        }}
      />
    )
    const buttonResume = (
      <Button
        key="video_btn_start"
        isEditor={this.props.isEditor}
        isLanding={this.props.isLanding}
        btnName={'button'}
        btn={this.props?.format?.button || { type: 'primary', text: 'Reprendre' }}
        callback={() => {
          this.setState((prevstate) => ({ playing: true, isStarted: true }))
        }}
      />
    )

    return (
      <div className="format_video formatBox">
        {!this.state.isMobile && (
          <VideoPlayer
            key={this.props.format.playbackId}
            urlID={this.props.format.playbackId}
            playing={this.state.playing}
            isEditor={this.props.isEditor}
            changeVideoState={(value) => {
              this.setState((prevstate) => ({ playing: value }))
            }}
            changeAlreadyPlayed={(value) => {
              this.setState((prevstate) => ({ isAlreadyPlayed: value }))
            }}
          />
        )}
        <div
          className={
            this.state.playing && !this.state.isMobile ? 'video_overlay isHidden' : 'video_overlay'
          }
        >
          <div className="format_wrapper">
            <div className="main_wrapper">
              <h1 className="xs">
                <EditableField
                  isLanding={this.props.isLanding}
                  tag="span"
                  field={
                    this.props.format.exerciceTitle ? this.props.format.exerciceTitle : 'vidéo'
                  }
                  isEditor={this.props.isEditor}
                  type="exerciceTitle"
                />{' '}
                -{' '}
                <i>
                  <EditableField
                    isLanding={this.props.isLanding}
                    tag="span"
                    field={this.props.format.duration}
                    isEditor={this.props.isEditor}
                    type="duration"
                  />{' '}
                  min
                </i>
              </h1>
              <EditableField
                isLanding={this.props.isLanding}
                tag="h2"
                field={this.props.format.title}
                isEditor={this.props.isEditor}
                type="title"
              />
              <EditableArea
                isLanding={this.props.isLanding}
                tag="div"
                field={this.props.format.text}
                isEditor={this.props.isEditor}
                type="text"
              />

              {this.state.isMobile && (
                <div className="mobile-video-wrapper">
                  <VideoPlayer
                    key={this.props.format.playbackId}
                    urlID={this.props.format.playbackId}
                    playing={this.state.playing}
                    isEditor={this.props.isEditor}
                    changeVideoState={(value) => {
                      this.setState((prevstate) => ({ playing: value }))
                    }}
                    changeAlreadyPlayed={(value) => {
                      this.setState((prevstate) => ({ isAlreadyPlayed: value }))
                    }}
                  />
                  {!this.state.playing && (
                    <img
                      src={this.state.isStarted ? replayButton : playButton}
                      className="mobile-play-button"
                      onClick={() => {
                        this.setState((prevstate) => ({ playing: true, isStarted: true }))
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            {this.state.isStarted === false && !this.state.playing &&
              <div className="btn_wrapper"> {buttonStart} </div>
            }
            {this.state.isStarted === true && this.state.isAlreadyPlayed === false && !this.state.playing &&
              <div className="btn_wrapper"> {buttonResume} </div>
            }
            {this.state.isStarted === true && this.state.isAlreadyPlayed === true &&
              <div className="btn_wrapper">
                {' '}
                {!this.state.isMobile && buttonReplay} {buttonNext}{' '}
              </div>
            }

          </div>
        </div>

        {this.props.isEditor && (
          <>
            <FormatStepEditor
              steps={[
                {
                  text: '1',
                  callback: () => {
                    this.setState((prevstate) => ({ playing: false, isStarted: false, isAlreadyPlayed:false }))
                  },
                },
                {
                  text: '2',
                  callback: () => {
                    this.setState((prevstate) => ({ playing: true, isStarted: true, isAlreadyPlayed:false }))
                  },
                },
                {
                  text: '3',
                  callback: () => {
                    this.setState((prevstate) => ({ playing: false, isStarted: true, isAlreadyPlayed:false }))
                  },
                },
                {
                  text: '4',
                  callback: () => {
                    this.setState((prevstate) => ({ playing: false, isStarted: true, isAlreadyPlayed:true }))
                  },
                },
              ]}
            />
            <Dropdown>
              <div className="dropdown_item dropdown_field">
                <h5>Id Vidéo:</h5>
                <EditableField
                  isLanding={this.props.isLanding}
                  tag="p"
                  field={this.props.format.playbackId}
                  isEditor={true}
                  type="playbackId"
                />
              </div>
            </Dropdown>
          </>
        )}
      </div>
    )
  }
}

export default FormatVideo
FormatVideo.contextType = LanguageContext
