import React, { useEffect, useContext, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { initData } from '../../actions/formationActions'

import { loginUser } from '../../actions/authActions'

import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'

import LanguageContext from "../../contexts/LanguageContext"

import { Box, FormControl, Select, MenuItem, } from '@material-ui/core'

import Layout, { StyledTextField, StyledButton } from "../mainLayout/Layout"

import frenchLanguage from '../../img/frenchLanguage.png';
import englishLanguage from '../../img/englishLanguage.png';
import { languages } from "./Register"


const Login = (props) => {
  const languageContext = useContext(LanguageContext);

  const languageRef = useRef(null)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const submitLogin = (e) => {
    e.preventDefault()
    const userData = {
      email: email.toLowerCase(),
      password: password,
      language : languageContext.language,
    }
    props.loginUser(userData) // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  }

  useEffect(() => {
    if (props.auth.isAuthenticated === true) {
      props.history.push("/dashboard");
    }
  }, [props.auth])

  return (
    <Layout linktorisupwebsite="true" componentsDown={
      <Box onClick={() => { props.history.push("/register") }} sx={{cursor:"pointer", fontSize:"14px", display:"flex", gap:"5px"}}>
        <Box sx={{color:"#626262"}}>
          {languageContext.values.login.createAccount[languageContext.language]}
          &nbsp;<u style={{color:"black"}}>{languageContext.values.global.click[languageContext.language]}</u>
        </Box>
      </Box>
    }>
      <Box sx={{marginBottom:"30px", fontSize: "25px", fontWeight:"bold",}}>
        {languageContext.values.login.title[languageContext.language]}
      </Box>
      <form onSubmit={(e) => {
        e.preventDefault();
        if (email !== "" && password !== "") {
          submitLogin(e)
        }
      }}>
        <FormControl fullWidth>
          <Select
            ref={languageRef}
            value={languageContext.language}
            name="language"
            onChange={(e) => { languageContext.changeLanguage(e.target.value) }}
            onClose={() => {
              languageRef.current.classList.remove('Mui-focused');
              languageRef.current.previousSibling?.classList.remove('Mui-focused');
             }}
            onOpen={() => {
              languageRef.current.classList?.add('Mui-focused');
              languageRef.current.previousSibling?.classList.add('Mui-focused');
            }}
            sx={{
              color: 'black',
              background:"#EDEDED",
              marginBottom:"15px",
              '& .MuiSelect-select': {
                padding:"10px",
                display:'flex',
                alignItems:"center",
                gap:"15px",
                border:""
              },
              '&.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#BEBEBE',
                  borderRadius: '6px',
                },
                '&:hover fieldset': {
                  borderColor: '#BEBEBE',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#0C457C',
                },
              },
            }}
            MenuProps={{
              sx: {
              '& .MuiMenu-paper': {
                //marginTop:"5px",
                backgroundColor: '#EDEDED',
                borderColor: '#BEBEBE',
                borderRadius: '6px',
              },
              '& .MuiMenuItem-root': {
                  gap:"15px",
              },
              '& .MuiMenuItem-root:hover': {
                  backgroundColor: '#EDEDED',
                  color : "#0C457C",
              },
              '& .Mui-selected': {
                  backgroundColor: '#EDEDED',
              }
              }
            }}
          >
            {languages.map((option, key) => (
              <MenuItem value={option.value} key={key}>
                <img style={{width:"35px", height:"35px"}} src={option.src} alt={option.label} />{option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <StyledTextField
          fullWidth
          autoComplete="on"
          error={props.errors.email !== undefined || props.errors.emailnotfound !== undefined}
          label={languageContext.values.login.emailField[languageContext.language]}
          variant="outlined"
          value={email}
          name="email"
          onChange={(e) => { setEmail(e.target.value)}}
        />
        <StyledTextField
          fullWidth
          autoComplete="on"
          error={props.errors.password !== undefined || props.errors.passwordincorrect !== undefined}
          label={languageContext.values.login.passwordField[languageContext.language]}
          variant="outlined"
          name="password"
          type="password"
          value={password}
          onChange={(e) => { setPassword(e.target.value)}}
        />
        <input type="submit" style={{display: "none"}} />
        <StyledButton
          disabled={email === "" || password === ""}
          fullWidth
          variant="contained"
          sx={{marginTop:"10px"}}
          onClick={submitLogin}
        >
          {languageContext.values.login.formValid[languageContext.language]}
        </StyledButton>
      </form>
      <Box
        onClick={() => { props.history.push("/mdp-perdu") }}
        sx={{
          marginTop:"25px",
          cursor:"pointer",
          fontSize:"14px",
          color:"#626262"
        }}
      >
        <u>{languageContext.values.login.forgotPassword[languageContext.language]}</u>
      </Box>
      <Box
        onClick={() => { window.location.href = "./eduserv/login" }}
        sx={{
          marginTop:"10px",
          cursor:"pointer",
          fontSize:"14px",
          color:"#626262"
        }}
      >
        <u>{languageContext.values.login.eduServ[languageContext.language]}</u>
      </Box>
    </Layout>
  )
}



Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})
export default connect(mapStateToProps, { loginUser, initData })(Login)
