import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import Loader from 'react-loader-spinner'

import FN from '../../../utils/functions.js'
import sound from '../../../img/speaker.svg'
import fullscreen from '../../../img/fullscreen.svg'

class VideoPlayer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      playing: this.props.playing,
      played: 0,
      volume: 0.8,
      isFullscreen: false,
      alreadyPlayed : false,
      isMobile: window.innerWidth <= 600,
    }
  }

  updateIsMobile() {
    this.setState(() => ({ isMobile: window.innerWidth <= 600 }))
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateIsMobile.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile.bind(this))
  }

  tooglePlayer = () => {
    if (screenfull.isFullscreen) {
      this.setState({ isFullscreen: false })
      this.existFullscreen()
    }
    var status = !this.state.playing
    this.setState({ playing: status })
    this.props.changeVideoState(status)
  }

  toogleFullscreen = () => {
    if (screenfull.isFullscreen) {
      this.setState({ isFullscreen: false })
      this.existFullscreen()
    } else {
      this.setState({ isFullscreen: true })
      screenfull.request(document.getElementById('videoBox'))
    }
  }

  existFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
      setTimeout(function () {
        document.webkitExitFullscreen()
      }, 100)
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  }

  onPlay = () => {
    if (!FN.isIphone() && !screenfull.isFullscreen) {
      screenfull.request(document.getElementById('videoBox'))
      document.getElementById('videoBox').classList.add('active')
    } else if (FN.isIphone()) {
      /*var video = this.player.getInternalPlayer()
      video.addEventListener(
        'webkitendfullscreen',
        () => {
          this.setState({ playing: false })
          this.onEnded({})
        },
        false
      )*/
      this.setState({ playing: true, played: 1 })
      this.props.changeVideoState('playing', true)
      return
    }
    this.setState({ playing: true, light: false })
    this.props.changeVideoState('playing', true)
  }

  onSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) })
    this.player.seekTo(parseFloat(e.target.value) / 100, 'fraction')
  }

  onEnded = (e) => {
    console.log("onended")
    this.tooglePlayer()
    this.props.changeAlreadyPlayed(true)
    this.setState({ played: 0, alreadyPlayed : true })
  }

  onError = (e) => {
    console.log("onerror")
    console.log(e)
    this.setState({ playing: false })
    this.props.changeVideoState(false)
    this.props.changeAlreadyPlayed(true)
    this.setState({ played: 0, alreadyPlayed : true })
    console.log("end onerror")
  }
  render() {
    this.state.playing = this.props.playing
    return (
      <div
        id="videoBox"
        className={
          (this.state.playing
            ? this.state.isFullscreen
              ? 'isPlaying isFullscreen'
              : 'isPlaying'
            : 'isHidden') + ' videoBox'
        }
      >
        <img
          src={
            'https://image.mux.com/' +
            this.props.urlID +
            '/thumbnail.png?width=949&height=499&fit_mode=crop'
          }
          className="previeVideo"
        />
        {(!this.props.isEditor || this.state.playing) && (
          <ReactPlayer
            loop={false}
            config={{ file: { attributes: { playsInline: true, }, }, }}
            url={'https://stream.mux.com/' + this.props.urlID + '.m3u8'}
            volume={this.state.volume}
            width={'100%'}
            height={'100%'}
            onPause={(e) => {console.log("onPause")}}
            onError={this.onError}
            onEnded={this.onEnded}
            playing={this.state.playing}
            onProgress={(e) => {
              console.log(e.played * 100)
              this.setState({ played: e.played * 100 })
              if (e.played * 100 >= 100) {
                this.onError()
              }
            }}
            onClick={this.tooglePlayer}
            ref={(player) => {
              this.player = player
            }}
          />
        )}
        {/* <Loader type="Puff" color="white" height={100} width={100} className="videoSpinner" /> */}
        <div className="videoControl">
          <button
            style={{background:"rgba(0,0,0, 0.5)", borderRadius:'50%'}}
            onClick={() => {
              this.tooglePlayer()
            }}
          >
            <span>{!this.state.playing ? '►' : 'II'}</span>
          </button>
          {FN.isIphone() === false &&
            <button
              style={{ marginLeft: '15px', background:"rgba(0,0,0, 0.5)", borderRadius:'50%' }}
              onClick={() => {
                this.toogleFullscreen()
              }}
            >
              <span>
                <img src={fullscreen} />
              </span>
            </button>
          }
          <div
            style={{ margin: '0 15px', width: '250px', position: 'relative' }}
            className="progressInput"
          >
            <progress max="100" value={this.state.played} />
            <input
              type="range"
              value={this.state.played}
              onChange={(e) => { /*this.onSeekChange(e)*/ }}
              onMouseDown={(e) => {
                //this.setState({ playing: false })
              }}
              onMouseUp={(e) => {
                //this.setState({ playing: true })
              }}
            />
          </div>
          {this.state.isMobile === false &&
            <div className="progressInput sound">
              <progress max="1" value={this.state.volume} />
              <input
                value={this.state.volume}
                onChange={(e) => {
                  this.setState({ volume: parseFloat(e.target.value) })
                }}
                type="range"
                min={0}
                max={1}
                step="any"
              />
              <img style={{ width: '16px', transform: 'rotate(90deg)' }} src={sound} />
            </div>
          }
          {this.state.isMobile === true && FN.isIphone() === false &&
            <div className="progressInput sound">
              <img
                style={{ opacity:this.state.volume >= 0.5 ? 1: 0.5, width: '16px', transform: 'rotate(90deg)' }}
                src={sound}
                onClick={() => {
                  if (this.state.volume >= 0.5) {
                    this.setState({ volume: 0 })
                  } else {
                    this.setState({ volume: 0.8 })
                  }
                }}
              />
            </div>
          }
        </div>
      </div>
    )
  }
}

export default VideoPlayer
